import React from 'react'
import styled from 'styled-components'

const Styling = styled.section`
/* display: flex; */
z-index: 100;
position: relative;
/* background: var(--blue_light); */
overflow: hidden;
width:100%;
background:var(--blue_dark);

    .intro{
    width:100%;
    height:100vh;
    position: relative;
    display:flex;
    align-items:center;
    align-content:center;

        h1.heading{
        font-size:clamp(3.7rem, 6vw, 8rem);
        width:100%;
        z-index:100;
        position: relative;
        color:var(--white);
        padding-left:2rem;

            @media only screen and (min-width: 450px) {
                padding-left:4rem;
            }
        
            @media only screen and (min-width: 760px) {
            padding-left:8rem;
            }

            .minerva{
            /* text-align:right; */
            opacity: 0;
            transform:translateY(3rem);
            ${({theme}) => theme.easing('1')}  
            transition-delay:0.75s;
            }
        
            .libre{
            opacity: 0;
            transform:translateY(3rem);
            ${({theme}) => theme.easing('1')}  
            transition-delay:0.5s;
            }

            @media only screen and (min-width: 760px) {
            font-size:clamp(4rem, 6vw, 8rem);
            line-height: inherit;
            width:auto;
            }


        }

        .parallax-outer{
        width: 100%;
        height: 140vh;
        position: absolute;
        top:0;

          
        
            .gatsby-image-wrapper{
            background:var(--blue_dark);
            width:100%;

                div{
                background:var(--blue_dark) !important;
                }

                &:before{
                content:"";
                position:absolute;
                top:0;
                left:0;
                width:100%;
                height:100%;
                background: linear-gradient(to top, rgba(60, 74, 119, 0) 50%, rgba(3,6,15, 9) 100%);
                z-index:10;
                /* mix-blend-mode:multiply; */
                }
                img{
                min-height:105vh;
                min-width:100%;
                object-fit:cover;
                }
            } 
            
        }

        

        &.is_inview{
            h1.heading{

                .minerva,
                .libre{
                opacity: 1;
                transform: translateY(0);
                }
            }

           
        }
    }
`

export { Styling }