import React from 'react'
import styled from 'styled-components'

const Styling = styled.section`
position: relative;
width: 100%;
overflow: hidden;
z-index: 100;

    @media only screen and (min-width: 600px) {
        min-height: 80vh;
    }

    &:before{
        background:var(--grey);
        content:"";
        height:100%;
        left:0;
        mix-blend-mode: multiply;
        opacity: 0.9;
        position:absolute;
        top:0;
        width:100%;
        z-index: 10;
    }

    .text{
    z-index: 100;
    position: absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    display: flex;
    justify-content: flex-start;
    align-items: center;

        .text_inner{
        padding:0 2rem;
        max-width:102.4rem;

            @media only screen and (min-width: 450px) {
            padding:0 10vw;
            }
        

            p{
            ${({theme}) => theme.fontSizeLineHeight('16', '1.5')}  
            margin-bottom: 2rem;
            }

        }
        
        .title{
        color:var(--blue_light);
        margin-bottom: 2rem;
        font-size:clamp(3rem, 12vw, 9rem);
        line-height: 1em;
        }

        .text_inner_text{
        color:var(--blue_light);
        margin-bottom: 4rem;
        }
        
    }

    .parallax{
  
        .image{
        height:100vh;
        width: 100vw;
        
            @media only screen and (min-width: 2000px) {
            top:0;
            }
            
        }
    }
`

export { Styling }