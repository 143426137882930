import React from 'react'
import { Styling } from './styled.js'
import {Link} from 'gatsby'
import {GatsbyImage,  getImage} from 'gatsby-plugin-image';
import parse from 'html-react-parser'

export default function About({aboutImage, aboutHeading, aboutText}) {
    const theImage = getImage(aboutImage.localFile);
    return (
        <Styling>
              <div className="about">
                <div className="image-wrapper">
                    <GatsbyImage image={theImage} className="image" alt={'test'} />
                </div>
                <div className="text">
                    <h4 className={'libre large title'}>{aboutHeading}</h4>
                    <div className="text-inner">
                        {parse(aboutText)}
                    </div>
                    <Link to="/our-showroom/" className='button'>Explore our bathroom showroom</Link>
                </div>
              </div>
        </Styling>
    )
}
