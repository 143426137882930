
import React, { useRef,  useEffect, useState } from "react";
import { Styling } from './styled.js'
import {Link} from 'gatsby'
import {GatsbyImage,  getImage} from 'gatsby-plugin-image';


let windowWidth;

if(typeof window !== 'undefined'){
    windowWidth = window.innerWidth;
}

export default function Products({productSlider}) {
    

    return (
        <Styling>
            {
            productSlider.map((slide, index) => {
                const theImage = getImage(slide.product.customHeroImage.heroImage.localFile);
                return (
                <Link to={slide.product.link}>
                    <h2 className="title minerva">{slide.title}</h2>
                    <GatsbyImage image={theImage} className="image" alt={'test'} />
                </Link>
                )
            })
            }
            {/* <Link to="/products/" className="button">View all our bathroom products</Link> */}
        </Styling>
    )
}
