import React from 'react'
import { graphql } from 'gatsby'
import Intro from '../components/homepage/Intro'
import Products from '../components/homepage/Products'
import About from '../components/homepage/About'
import ParallaxSection from '../components/homepage/ParallaxSection'
import CTASection from '../components/global/CTASection'
import Texture from '../components/animation/Texture'
import styled from 'styled-components'
import {Helmet} from 'react-helmet'

const Home = styled.div`
  .texture_wrap_outer{
  opacity: 0.05;

    /* @media only screen and (min-width: 720px) {
    display: none;
    } */

  }
`

export default function Homepage({data}) {

  const {
      aboutHeading, 
      aboutText, 
      aboutImages, 
      heading1, 
      heading2, 
      image, 
      productSlider,
      parallaxHeading, 
      parallaxText, 
      parallaxImage
    } = data.wpPage.homepageFields;

  return (
    <Home>
      <Helmet>
          <title>{data.wpPage.seo.title}</title>
          <meta name="description" content={data.wpPage.seo.metaDesc} />
          <meta name="og:description" content={data.wpPage.seo.metaDesc} />
          <meta property="og:title" content={data.wpPage.seo.title} />          
          <meta property="og:image" content={'https://morlandbathrooms.com/'+image.localFile.publicURL} />
      </Helmet>
      <div className="offpage-translate offpage-translate-bg"></div>
      <div className="offpage-translate">
          <Intro heading1={heading1} heading2={heading2} image={image} />
          <Products productSlider={productSlider}/>
          <About aboutHeading={aboutHeading} aboutText={aboutText} aboutImage={aboutImages} />
          <ParallaxSection parallaxHeading={parallaxHeading} parallaxText={parallaxText} parallaxImage={parallaxImage} />
          <CTASection ctaType={'chat'} />
          <div className="texture_wrap_outer">
            <Texture className='texture fullViewpoint' watchScroll={false} />
          </div>
      </div>
    </Home>
  )
}


export const query = graphql`
  {
    wpPage(title:{eq:"Homepage"}) {
      seo {
        title
        metaDesc
      }
      homepageFields {
        aboutHeading
        aboutText
        heading1
        heading2
        parallaxHeading
        parallaxText

        aboutImages {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 2000
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        image {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 2000
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        productSlider {
          title
          product {
            ... on WpPage {
              id
              title
              link
              customHeroImage{
                heroImage{
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        width: 2000
                        placeholder: NONE
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
            }
            ... on WpProduct {
              id
              title
              link
              customHeroImage{
                heroImage{
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        width: 2000
                        placeholder: NONE
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
            }
          }
        }
        parallaxImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 2000
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  }
`