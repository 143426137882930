import React, {useEffect, useState, useRef} from 'react'
import { Styling } from './styled.js'
import {GatsbyImage,  getImage} from 'gatsby-plugin-image';
import { Parallax } from 'react-scroll-parallax'

export default function Intro({heading1, heading2, image}) {

  const [intro, setIntro] = useState()
  const introRef = useRef()

  useEffect(() => {
    setIntro(introRef.current)
    
    if(intro){
      intro.classList.add('is_inview')
    }

  }, [intro])
  const theImage = getImage(image.localFile);
  return (
    <Styling>
      <div className="intro" ref={introRef}>
        <h1 className='heading'>
          <div className="libre">{heading1}</div>
          <div className="minerva">{heading2}</div>
        </h1>

        <Parallax className="parallax" y={[-20, 20]} tagOuter="figure">
          <GatsbyImage  loading="eager" image={theImage} className="image" alt={'test'} />
        </Parallax>
      </div>  
    </Styling>
  )
}
