import React from 'react'
import styled from 'styled-components'

const Styling = styled.section`
text-align:center;
z-index: 100;
position: relative;
display:grid;
grid-template-columns:repeat(1, auto);
grid-auto-rows: 1fr;
grid-gap:2rem;
padding:4rem 4rem 8rem 4rem;
max-width:100%;
margin:0 auto;

    @media only screen and (min-width: 660px) {
        grid-template-columns:repeat(2, auto);     
    }

    @media only screen and (min-width: 1024px) {
        grid-template-columns:repeat(3, auto);     
    }

    @media only screen and (min-width: 1200px) {
    padding:8rem 0;
    max-width:calc(80% - 4rem);
    }

    a{
    position: relative;
    /* aspect-ratio:16 / 9; */
    max-height:250px;
    color:var(--white);
    overflow: hidden;

        &:before{
        content:"";
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        background: linear-gradient(to bottom, rgba(60, 74, 119, 0) 0%, rgba(28,36,66, 1) 100%);
        z-index:10;
        opacity:0.7;
        mix-blend-mode:multiply;
        transition:0.5s all ease-in-out;
        }

        .title{
        position:absolute;
        left:2rem;
        bottom:2rem;
        z-index:10;
        font-size:2rem;
        text-align: left;
        }

        .image{
        height:100%;
        transition:0.5s all ease-in-out;
        }
        
        &:hover{
            &:before{
                opacity:1;                
            }
            .image{
            transform:scale(1.05);
            }
        }

        @media only screen and (min-width: 1024px) {
    
            &:nth-child(1){
            grid-column:1 / 2;
            grid-row: 1 / 2;
            }
    
            &:nth-child(2){
            grid-column:2 / 3;
            grid-row: 1 / 2;
            }
            
            &:nth-child(3){
            grid-column:3 / 4;
            grid-row: 1 / 3;
            max-height:inherit;
            }
            
            &:nth-child(4){
            grid-column:1 / 3;
            grid-row: 2 / 3;
            }
    
            &:nth-child(5){
            grid-column:2 / 3;
            grid-row: 3 / 4;
            }
            
            &:nth-child(6){
            grid-column:3 / 4;
            grid-row: 3 / 4;
            }
            
            &:nth-child(7){
            grid-column:1 / 2;
            grid-row: 3 / 5;
            max-height:inherit;
            }
            
            &:nth-child(8){
            grid-column:2 / 4;
            grid-row: 4 / 5;
            }
            
        }

    }

`

export { Styling }