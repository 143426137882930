import React from 'react'
import styled from 'styled-components'

const Styling = styled.section`
width:100%;
display: flex;
justify-content: center;
align-items: center;
z-index: 100;
position: relative;
padding:0 0 4rem 0;

    @media only screen and (min-width: 660px) {
        padding:10rem 0;
    }

    @media only screen and (min-width: 1024px) {
    /* min-height:100vh; */
    padding:0 0 8rem 0;
    }


    .about{
    margin:0 auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-width: 100%;
    padding:0 2rem;

        @media only screen and (min-width: 450px) {
        padding:0;
        max-width: 80%;
        }


        @media only screen and (min-width: 760px) {
        flex-wrap: nowrap;
        }

    
        .image-wrapper{
        width:100%;
        order:2;
        
        
            @media only screen and (min-width: 760px) {
            margin-right: 8rem;
            width:50%;
            order:1;
            }

            @media only screen and (min-width: 1100px) {
            width:50rem;
            }

            
            .image{
            //border:1rem solid var(--white);
            background:var(--white);        
            height: 0;
            padding-bottom: 70%;

                @media only screen and (min-width: 760px) {
                padding-bottom:0;
                height: auto;
                }

            }
        }

        .text{
        width:100%;
        order:1;
        margin-bottom: 8rem;

            @media only screen and (min-width: 760px) {
            width: 50%;
            order:2;
            margin-bottom: 0;
            }
            
            @media only screen and (min-width: 1100px) {
            width: auto;
            }

            .title{
            margin-bottom:4rem;
            }

            .text-inner{
            max-width: 600px;
            margin-bottom:4rem;

                p{
                ${({theme}) => theme.fontSizeLineHeight('16', '1.5')}  
                margin-bottom:2rem;
                }
            }
        }

    }

`

export { Styling }