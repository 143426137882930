import React from 'react'
import { Styling } from './styled.js'
import { Parallax } from 'react-scroll-parallax'
import parse from 'html-react-parser'
import {Link} from 'gatsby'
import {GatsbyImage,  getImage} from 'gatsby-plugin-image'

export default function ParallaxSection({parallaxHeading, parallaxText, parallaxImage}) {
    const theImage = getImage(parallaxImage.localFile);
    return (
        <Styling>
            <div className="text">
                <div className="text_inner">
                    <h3 className="title minerva x_large">{parallaxHeading}</h3>
                    <div className="text_inner_text">{parse(parallaxText)}</div>
                    <Link to="/contact/" className='button--light'>Book a design consultation now</Link>
                </div>
            </div>
            <div className="parallax">
                <Parallax className="custom-class" y={[-20, 20]} tagOuter="figure">
                    <GatsbyImage image={theImage} className="image" alt={'test'} />
                </Parallax>
            </div>
        </Styling>
    )
}
